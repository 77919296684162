<template>
  <b-button class="btn" :variant="variant" :disabled="disabled" @click="goBack"
   v-tooltip="label">
    <span class="content">
      <fa-icon v-if="!!icon" :icon="icon" size="sm" />
      <span :class="{ 'pl-2': !!icon }">{{ label }}</span>
    </span>
  </b-button>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';

import NavigationHelper from '../../helpers/NavigationHelper';
import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class BackLink extends ComponenteBase {
  @Prop({ type: String, default: '' })
  private label!: string;

  @Prop({ type: String, default: '' })
  private icon!: string;

  @Prop({ type: String, default: 'danger' })
  private variant!: string;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: String, default: '' })
  private title!: string;

  public goBack() {
    NavigationHelper.goBack();
  }
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 120px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
