
<script lang="ts">
import { Vue } from 'vue-property-decorator';

import TipoDeAction from '../constants/enums/TipoDeAction';
import DateHelper from '../helpers/DateHelper';
import Permissions from '../helpers/Permissions';
import IDictionary from '@/models/Interfaces/IDictionary';

declare global {
  interface Array<T> {
    distintoPor<T>(this: T[], key: string): T[];
    agruparPor<T>(this: T[], key: string) : T[][];
    comoDictionaryArray<TKey, TValue>(this: any[], keyProperty: string, valueProperty: string): IDictionary<TKey, TValue>[];
  }
  interface String {
    estaPreenchido(this: string): boolean;
  }

  /**
   * Função recursiva que itera um objeto e adiciona suas propriedades em um FormData
   * @param formData FormData que será preenchido
   * @param objeto Objeto que será iterado
   * @param filtros Lista de nomes de propriedades que não serão incluídas
   */
  function popularFormData(formData: FormData, objeto: any, filtros: string[]): FormData;
}

function distintoPor<T>(this: T[], key: string) {
  return [...new Map(this
    .map((item) => ([item[key], item]))).values()];
}

function agruparPor<T>(this: T[], key: string) : T[][] {
  return Object.values(this.reduce((acumulador, valorAtual) => {
    (acumulador[valorAtual[key]] = acumulador[valorAtual[key]] || []) // eslint-disable-line
      .push(valorAtual);
    return acumulador;
  }, {} as any));
}

function estaPreenchido(this: string) : boolean {
  const retorno = this.trim();
  return !(retorno === '' || retorno === 'null' || !retorno);
}

function comoDictionaryArray<TKey, TValue>(this: any[], keyProperty: string, valueProperty: string) : IDictionary<TKey, TValue>[] {
  if (!this) return [];
  return this.map((item) => ({ key: item[keyProperty], value: item[valueProperty] })) as IDictionary<TKey, TValue>[];
}

function popularFormData(formData: FormData, objeto: any, filtros: string[], chavePai: string = '') {
  Object.keys(objeto).forEach((chave) => {
    if (!filtros.includes(chave)) {
      if (objeto[chave]) {
        const formKey = chavePai ? `${chavePai}[${chave}]` : chave;
        if (typeof objeto[chave] === 'object' && !(objeto[chave] instanceof File)) {
          popularFormData(formData, objeto[chave], filtros, formKey);
        } else {
          formData.append(formKey, objeto[chave]);
        }
      }
    }
  });
  return formData;
}

/// Registra funções globais
const _global = (window || global) as any;
_global.popularFormData = popularFormData;

/// Registra extensões
// eslint-disable-next-line
Array.prototype.distintoPor = distintoPor;
// eslint-disable-next-line
Array.prototype.agruparPor = agruparPor;
// eslint-disable-next-line
String.prototype.estaPreenchido = estaPreenchido;
// eslint-disable-next-line
Array.prototype.comoDictionaryArray = comoDictionaryArray;

export default class ComponenteBase extends Vue {
  public readonly MENSAGEM_NAO_INFORMADO: string = 'Não Informado';

  public readonly MENSAGEM_NAO_INFORMADA: string = 'Não Informada';

  public readonly MASCARA_DE_DATA: string = '_____/_____/________';

  get permissions(): typeof Permissions {
    return Permissions;
  }

  get tipoDeAction(): typeof TipoDeAction {
    return TipoDeAction;
  }

  public nomeDaVariavelComoString(variavel: any): string {
    return Object.keys({ variavel })[0];
  }

  public compararTipoDeAction(tipo: TipoDeAction): boolean {
    return this.$route.params.action as any === tipo;
  }

  public distinctBy(array: Array<any>, key: string) {
    return [...new Map(array
      .map((item) => ([item[key], item]))).values()];
  }

  public groupBy<T, Tkey extends keyof T>(array: Array<T>, key: Tkey) : T[][] {
    return Object.values(array.reduce((acumulador, valorAtual) => {
      (acumulador[valorAtual[key]] = acumulador[valorAtual[key]] || []) // eslint-disable-line
        .push(valorAtual);
      return acumulador;
    }, {} as any));
  }

  public stringNulaOuVazia(palavra?: string) : boolean {
    return palavra === '' || palavra === null || palavra === 'null' || palavra === undefined;
  }

  public formataMoeda(valor: number): string {
    return valor?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  public formataData(str: string) {
    if (str === '0001-01-01T00:00:00') {
      return 'Não Informada';
    }

    return DateHelper.formatBR(str);
  }

  public formataNumeroTelefone(numero: string): string {
    const regex = /^(\d{2})(\d{5})(\d{4})$/;
    return numero.replace(regex, '($1) $2-$3');
  }

  // converte dataURL para arquivo
  public dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /**
 * Procura por âncoras na URL e move a tela para o elemento HTML em que ela pertence.
 * É necessário ter um elemento(div) com o ref da ancora.
 */
  public verificarAncoras() {
    const { hash } = this.$route;

    if (hash) {
      const ancora = hash.replace('#', '');
      const componente = (this.$refs[`${ancora}`] as HTMLElement);
      Vue.nextTick(() => {
        if (componente) {
          componente.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }
}
</script>
