import { DirectiveBinding } from 'vue';

type TooltipElement = HTMLElement & {
  _tooltipEl?: HTMLElement;
  _showTooltip?: (event: MouseEvent) => void;
  _hideTooltip?: () => void;
};

const tooltip = {
  bind(el: HTMLElement, binding: DirectiveBinding<any>) {
    const element = el as TooltipElement;
    const tooltipText = binding.value; // Use o valor passado para a diretiva

    // Verifique se o valor do tooltip está presente
    if (!tooltipText) {
      return;
    }

    // Remova o atributo title para evitar o tooltip nativo do navegador
    element.removeAttribute('title');

    const arrowPosition = element.getAttribute('data-arrow-position') || 'top'; // Use a posição da seta ou 'top' como padrão
    const tooltipBgColor = element.getAttribute('data-tooltip-bg') || '#000'; // Use a cor de fundo do atributo ou preto como padrão

    // Crie o elemento do tooltip
    const tooltipEl = document.createElement('div');
    tooltipEl.className = `tooltip ${arrowPosition}`; // Adicione a classe da posição da seta
    tooltipEl.innerText = tooltipText; // Atribua o texto diretamente
    tooltipEl.style.backgroundColor = tooltipBgColor; // Atribua a cor de fundo
    tooltipEl.style.color = '#fff'; // Adicione a cor do texto para visibilidade
    document.body.appendChild(tooltipEl);

    const updateTooltipPosition = (event: MouseEvent) => {
      const {
        top,
        left,
        width,
        height,
      } = element.getBoundingClientRect();

      const {
        width: tooltipWidth,
        height: tooltipHeight,
      } = tooltipEl.getBoundingClientRect();

      const { position } = element.dataset;

      switch (position) {
        case 'top':
          tooltipEl.style.top = `${top + window.scrollY - tooltipHeight - 10}px`;
          tooltipEl.style.left = `${left + window.scrollX + width / 2 - tooltipWidth / 2}px`;
          break;
        case 'right':
          tooltipEl.style.top = `${top + window.scrollY + height / 2 - tooltipHeight / 2}px`;
          tooltipEl.style.left = `${left + window.scrollX + width + 10}px`;
          break;
        case 'bottom':
          tooltipEl.style.top = `${top + window.scrollY + height + 10}px`;
          tooltipEl.style.left = `${left + window.scrollX + width / 2 - tooltipWidth / 2}px`;
          break;
        case 'left':
          tooltipEl.style.top = `${top + window.scrollY + height / 2 - tooltipHeight / 2}px`;
          tooltipEl.style.left = `${left + window.scrollX - tooltipWidth - 10}px`;
          break;
        default:
          tooltipEl.style.top = `${top + window.scrollY - tooltipHeight - 10}px`;
          tooltipEl.style.left = `${left + window.scrollX + width / 2 - tooltipWidth / 2}px`;
      }
    };

    const showTooltip = (event: MouseEvent) => {
      tooltipEl.style.display = 'block';
      updateTooltipPosition(event);
      tooltipEl.classList.add('show');
    };

    const hideTooltip = () => {
      tooltipEl.classList.remove('show');
      setTimeout(() => {
        tooltipEl.style.display = 'none';
      }, 300); // Espera a transição terminar
    };

    element._showTooltip = showTooltip;
    element._hideTooltip = hideTooltip;

    element.addEventListener('mouseenter', showTooltip);
    element.addEventListener('mousemove', updateTooltipPosition); // Atualiza a posição do tooltip durante o movimento do mouse
    element.addEventListener('mouseleave', hideTooltip);

    element._tooltipEl = tooltipEl;
  },
  unbind(el: HTMLElement) {
    const element = el as TooltipElement;

    if (element._tooltipEl) {
      document.body.removeChild(element._tooltipEl);
    }
    element.removeEventListener('mouseenter', element._showTooltip!);
    element.removeEventListener('mousemove', element._showTooltip!);
    element.removeEventListener('mouseleave', element._hideTooltip!);
  },
};

export default tooltip;
