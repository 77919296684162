<template>
  <b-button
    class="btn"
    :type="submit ? 'submit' : 'button'"
    :variant="variant"
    :size="size"
    :disabled="disabled"
    @click="handleClick"
     v-tooltip="returnLabel"
    :data-arrow-position="setaTooltip"
    :data-position="posicaoTooltip"
    >
    <b-spinner :variant="colorLoading" data-testid="button-loading" v-if="loading" small />

    <span v-else class="content" >
      <fa-icon data-testid="button-icon" v-if="!!icon" :icon="icon" size="sm" />
      <span
       data-testid="button-text" :class="{ 'pl-2': !!icon }">{{ label }}</span>
    </span>
  </b-button>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';

import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class Button extends ComponenteBase {
  @Prop({ type: String, default: '' })
  public label!: any;

  @Prop({ type: String, default: '' })
  public icon!: string;

  @Prop({ type: String, default: '' })
  public variant!: string;

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  public submit!: boolean;

  @Prop({ default: false })
  public loading!: boolean;

  @Prop({ type: String, default: 'md' })
  public size!: string;

  @Prop({ type: String, default: '' })
  public colorLoading!: string;

  @Prop({ type: String, default: '' })
  public title!: string;

  @Prop({ type: String, default: '' })
  public posicaoTooltip!: string;

  @Prop({ type: String, default: '' })
  public setaTooltip!: string;

  @Prop({ default: false })
  public ativarTooltip!: boolean;

  private _lastClick: number = 0;

  public returnLabel = this.title === '' ? this.label : this.title;


  /**  handleClick
   * * Método criado para evitar que cliques consecutivos em um curto período emitam múltiplos eventos
   * @param ev evento de click utilizado apenas para saber o momento em que ele ocorreu
   */
  public handleClick(ev: Event) {
    if (ev.timeStamp - this._lastClick > 300 || !this._lastClick) {
      this.$emit('click');
    }
    this._lastClick = ev.timeStamp;
  }
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 120px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
