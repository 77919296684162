<template>
  <b-button class="btn" type="submit" :disabled="disabled || loading" variant="success"
  v-tooltip="label">
    <b-spinner data-testid="button-loading" v-if="loading" small />
    <span v-else class="content">
      <fa-icon data-testid="button-icon" icon="save" size="sm" />
      <span data-testid="button-text" class="pl-2">{{ label }}</span>
    </span>
  </b-button>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';

import ComponenteBase from '../ComponenteBase.vue';

@Component
export default class SaveButton extends ComponenteBase {
  @Prop({ default: '' })
  private label!: string;

  @Prop({ default: false })
  private disabled!: boolean;

  @Prop({ default: false })
  private loading!: boolean;
}
</script>

<style lang="scss" scoped>
.btn {
  min-width: 120px;
}

.content {
  display: block;
  margin: 0 auto;
}
</style>
