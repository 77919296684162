export interface IPagedResult<T = any> {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
  results: T[];
  filtroRegistro?: number;
}

export default class PagedResult<T = any> implements IPagedResult<T> {
  public currentPage: number;

  public pageCount: number;

  public pageSize: number;

  public rowCount: number;

  public firstRowOnPage: number;

  public lastRowOnPage: number;

  public results: T[];

  public filtroRegistro?: number;

  constructor() {
    this.currentPage = 0;
    this.pageCount = 0;
    this.pageSize = 5;
    this.rowCount = 0;
    this.firstRowOnPage = 0;
    this.lastRowOnPage = 0;
    this.results = [];
    this.filtroRegistro = 1;
  }
}
